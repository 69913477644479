* {
  font-family: Poppins;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #001030;
  margin: 0;
}

h1 {
  font-weight: 600;
  font-size: 55px;
  text-align: center;
  margin: 70px 0 25px 0;
}

h2 {
  font-weight: 600;
  font-size: 30px;
  text-align: center;
}

h6 {
  font-size: 16px;
  text-align: center;
}

.header-link {
  text-decoration: none;
  opacity: 60%;
  transition: opacity 0.5s ease-out;
}

.header-link:hover,
.header-link:focus {
  text-decoration: underline;
  text-decoration-thickness: 3px;
  opacity: 100%;
}

.zoom {
  transition: transform .2s;
}

.zoom:hover {
  transform: scale(1.1);
}

